/* eslint-disable import/prefer-default-export */
export const optionsSeller = (t) => [
    {
        aclCode: 'dashboard',
        key: 'dashboard',
        label: t('menu:Dashboard'),
        url: '/seller/dashboard',
        notInAcl: true,
    },
    {
        aclCode: 'seller_order',
        key: 'order',
        label: t('menu:Order'),
        children: [
            {
                aclCode: 'seller_order',
                key: 'order_list',
                label: t('menu:Order_List'),
                url: '/seller/order/orderlist',
                notInAcl: true,
            },
            {
                aclCode: 'seller_order',
                key: 'order_failed',
                label: t('menu:Failed_Order_List'),
                url: '/seller/order/failedorder',
                notInAcl: true,
            },
        ],
    },
    {
        aclCode: 'oms_lite_header_sales',
        key: 'orderqueue',
        label: t('menu:Order'),
        children: [
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_all',
                label: t('menu:Order_Queue'),
                url: '/seller/order/allorder',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_failed',
                label: t('menu:Failed'),
                url: '/seller/order/failed',
            },
            {
                notInAcl: 'sales_order_queue_bulk_tools',
                key: 'orderqueue_bulk_tools',
                label: t('menu:Bulk_Tools'),
                url: '/seller/order/bulktools',
            },
            {
                aclCode: 'sales_order_update',
                key: 'update_order',
                label: t('menu:Update_Order'),
                url: '/seller/order/updateorder',
            },
            {
                aclCode: 'apollo_order_edit_index',
                key: 'orderqueue_all_edit',
                label: t('menu:Edit_Order_Shipped'),
                url: '/seller/order/ordershipped',
            },
        ],
    },
    {
        aclCode: 'header_shipment',
        key: 'shipment',
        label: t('menu:Shipment'),
        children: [
            {
                aclCode: 'shipment_marketplace_dashboard',
                key: 'shipmentmarketplace',
                label: t('menu:Marketplace'),
                url: '/seller/shipment/shipmentmarketplace',
            },
            {
                aclCode: 'shipment_delivery_dashboard',
                key: 'homedelivery',
                label: t('menu:Home_Delivery'),
                url: '/seller/shipment/homedelivery',
            },
        ],
    },
    {
        aclCode: 'seller_catalog',
        key: 'catalog',
        label: t('menu:Catalog'),
        url: '/seller/catalog',
        isMultichannel: false,
    },
    {
        aclCode: 'seller_catalog',
        key: 'catalogproduct',
        label: t('menu:Catalog'),
        isMultichannel: true,
        children: [
            {
                aclCode: 'seller_catalog_master_product',
                key: 'masterproduct',
                label: t('menu:Master_Product'),
                url: '/seller/catalogproduct/masterproduct',
            },
            {
                aclCode: 'seller_catalog_channel_product',
                key: 'channelproduct',
                label: t('menu:Channel_Product'),
                children: [
                ],
            },
            {
                aclCode: 'seller_catalog_channel_product_edit',
                key: 'masschange',
                label: t('menu:Mass_Change'),
                url: '/seller/catalogproduct/masschange',
            },
        ],
    },
    {
        aclCode: 'seller_stock',
        key: 'stock',
        label: t('menu:Stock'),
        url: '/seller/stock',
        isMultichannel: false,
    },
    {
        aclCode: 'seller_stock',
        key: 'stock',
        label: t('menu:Stock'),
        url: '/seller/stockchannel',
        isMultichannel: true,
        notInAcl: true,
    },
    // {
    //     key: 'discussion',
    //     label: t('menu:Discussion'),
    //     url: '/seller/discussion',
    // },
    // {
    //     key: 'review',
    //     label: t('menu:Review'),
    //     url: '/seller/review',
    // },
    {
        aclCode: 'seller_return',
        key: 'returns',
        label: t('menu:Return'),
        url: '/seller/return',
    },
    {
        aclCode: 'oms_lite_credit_memos',
        key: 'creditmemos',
        label: t('menu:Credit_Memos'),
        url: '/seller/creditmemos',
    },
    {
        aclCode: 'seller_store',
        key: 'storesetting',
        label: t('menu:Store_Setting'),
        url: '/seller/storesetting',
    },
    {
        aclCode: 'seller_income',
        key: 'income',
        label: t('menu:Income'),
        url: '/seller/income/balance',
    },
    // {
    //     aclCode: 'seller_promotion',
    //     key: 'promotion',
    //     label: t('menu:Promotion'),
    //     url: '/seller/promotion',
    // },
    {
        aclCode: 'seller_promotion',
        key: 'promotionchannel',
        label: t('menu:Promotion'),
        isMultichannel: true,
        children: [
            {
                aclCode: 'seller_promotion_discount',
                key: 'promotiondiscount',
                label: t('menu:Discount'),
                children: [
                ],
            },
        ],
    },
    {
        aclCode: 'oms_lite_promotion',
        key: 'promotiongwp',
        label: t('menu:Promotion_GWP'),
        url: '/seller/promotiongwp',
    },
    {
        aclCode: 'seller_sales_channel',
        key: 'saleschannel',
        label: t('menu:Store_Channel'),
        url: '/seller/saleschannel',
        isMultichannel: true,
    },
    {
        aclCode: 'seller_apps',
        key: 'apps',
        label: t('menu:Apps'),
        url: '/seller/apps',
        isMultichannel: true,
    },
    {
        aclCode: 'seller_user',
        key: 'manageuser',
        label: t('menu:Manage_User'),
        url: '/seller/manageuser',
    },
    {
        aclCode: 'phastos_login',
        key: 'phastos',
        label: 'IMS',
        children: [
            {
                aclCode: 'phastos_company_dashboard',
                key: 'sellerphastoscompany',
                label: t('menu:Company'),
                url: '/seller/phastos/company',
            },
            {
                aclCode: 'phastos_location_dashboard',
                key: 'phastoslocation',
                label: t('menu:Location'),
                url: '/seller/phastos/location',
            },
            {
                aclCode: 'phastos_saleschannel_dashboard',
                key: 'phastossaleschannel',
                label: t('menu:Sales_Channel'),
                url: '/seller/phastos/saleschannel',
            },
            {
                aclCode: 'phastos_virtual_stock_dashboard',
                key: 'phastosvirtualstock',
                label: t('menu:Virtual_Stock'),
                url: '/seller/phastos/virtualstock',
            },
            {
                aclCode: 'phastos_catalog_product_entity_dashboard',
                key: 'phastosproduct',
                label: t('menu:Product'),
                url: '/seller/phastos/product',
            },
            {
                aclCode: 'phastos_sku_assembly_configuration_dashboard',
                key: 'phastosproductassembly',
                label: t('menu:Product_Assembly'),
                url: '/seller/phastos/productassembly',
            },
            {
                aclCode: 'phastos_sourcing_dashboard',
                key: 'phastossource',
                label: t('menu:Source'),
                url: '/seller/phastos/source',
            },
            // {
            //     aclCode: 'phastos_audit_trail_dashboard',
            //     key: 'phastosaudittrail',
            //     label: t('menu:Audit_Trail'),
            //     url: '/seller/phastos/audittrail',
            // },
            {
                aclCode: 'phastos_allocation_rules_component_dashboard',
                key: 'phastosallocationrulescomponent',
                label: t('menu:Allocation_Rules_Component'),
                url: '/seller/phastos/allocationrules_component',
            },
            {
                aclCode: 'phastos_allocation_rules_item_dashboard',
                key: 'phastosallocationrulesitem',
                label: t('menu:Allocation_Rules_Item'),
                url: '/seller/phastos/allocationrules_item',
            },
            {
                aclCode: 'phastos_channel_mirroring_dashboard',
                key: 'phastoschannelmirroringgroup',
                label: t('menu:Channel_Mirroring_Group'),
                url: '/seller/phastos/channelmirroringgroup',
            },
            {
                aclCode: 'phastos_channel_stock_allocation_dashboard',
                key: 'phastoschannelstockallocation',
                label: t('menu:Channel_Stock_Allocation'),
                url: '/seller/phastos/channelstockallocation',
            },
            {
                aclCode: 'phastos_threshold_limit_config',
                key: 'phastosthresholdlimit',
                label: t('menu:Threshold_Limit'),
                url: '/seller/phastos/thresholdlimit',
            },
            {
                aclCode: 'phastos_override_stock',
                key: 'phastosoverridestock',
                label: t('menu:Override_Stock'),
                url: '/seller/phastos/overridestock',
            },
            {
                aclCode: 'phastos_cap_limit_config',
                key: 'phastoscaplimitconfig',
                label: t('menu:Cap_Limit_Config'),
                url: '/seller/phastos/caplimitconfig',
            },
        ],
    },
    {
        aclCode: 'seller_report',
        key: 'report',
        label: t('menu:Report'),
        notInAcl: true,
        children: [
            {
                aclCode: 'seller_audit_trail',
                key: 'reportaudittrail',
                label: t('menu:Audit_Trail'),
                notInAcl: true,
                children: [
                    {
                        aclCode: 'seller_audit_trail_master_product',
                        key: 'reportaudittrailmasterproduct',
                        label: t('menu:Master_Product'),
                        url: '/seller/report/audittrail/masterproduct',
                        notInAcl: true,
                    },
                    {
                        aclCode: 'seller_audit_trail_channel_product',
                        key: 'reportaudittrailchannelproduct',
                        label: t('menu:Channel_Product'),
                        url: '/seller/report/audittrail/channelproduct',
                        notInAcl: true,
                    },
                ],
            },
        ],
    },
];

export default optionsSeller;
