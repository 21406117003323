import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import * as Schema from '@modules/theme/services/graphql/schema';

const context = {
    context: { request: 'internal' },
};

const fetchPolicy = {
    fetchPolicy: 'cache-and-network',
};

export const customerAccessControlList = (variables) => useQuery(Schema.customerAccessControlList, {
    variables, ...context, ...fetchPolicy,
});

export const customerAccessControlListLazy = (variables) => useLazyQuery(Schema.customerAccessControlList, {
    variables, ...context, ...fetchPolicy,
});

export const getStoreConfig = (variables) => useQuery(Schema.getStoreConfig, {
    variables, ...context, ...fetchPolicy,
});

export const getStoreConfigLazy = (options) => useLazyQuery(Schema.getStoreConfig, {
    ...options, ...context, ...fetchPolicy,
});

export const isAccessAllowed = (variables) => useQuery(Schema.isAccessAllowed, {
    variables, ...context, fetchPolicy: 'no-cache',
});

export const isAccessAllowedLazy = (options) => useLazyQuery(Schema.isAccessAllowed, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getStoreLogo = (variables) => useQuery(Schema.getStoreLogo, {
    variables, ...context, ...fetchPolicy,
});

export const getCurrency = (variables) => useQuery(Schema.getCurrency, {
    variables, ...context, ...fetchPolicy,
});

export const csStores = (options) => useQuery(Schema.csStores, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const storeConfigMenu = (options) => useLazyQuery(Schema.storeConfigMenu, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const isAccessAllowedOptions = (options) => useQuery(Schema.isAccessAllowed, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const updateCustomerAttribute = (variables) => useMutation(Schema.updateCustomerAttribute, {
    ...variables,
    ...context,
});

export const katalisGetIntegration = (options) => useLazyQuery(Schema.katalisGetIntegration, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const katalisGetCreditBalance = (options) => useLazyQuery(Schema.katalisGetCreditBalance, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getVendorIrisBalance = (options) => useLazyQuery(Schema.getVendorIrisBalance, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export default {
    customerAccessControlList,
    customerAccessControlListLazy,
    getStoreConfig,
    getStoreConfigLazy,
    isAccessAllowed,
    isAccessAllowedLazy,
    isAccessAllowedOptions,
    getStoreLogo,
    getCurrency,
    csStores,
    storeConfigMenu,
    updateCustomerAttribute,
    katalisGetIntegration,
    katalisGetCreditBalance,
    getVendorIrisBalance,
};
